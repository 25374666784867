export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU",
        authDomain: "dcsptest-sso.service.deloitte.ai",
        projectId: "dcsptest"
    },
    region: "europe-west1",
    brain_base_url: "https://dcsp.api-dev.deloitte.camp",
    authParams: ["oidc.azure"],
    signInType: 'signInWithPopup',
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            deloitteAiCatalog: "Deloitte.Ai",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
            myEntitlements: "My Entitlements"
        }
    },
    aoh_url: "https://aoh-dev.solutions.deloitte.ai",
    store_url: "https://store-dev.solutions.deloitte.ai",
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    api_base_url: "https://dcsp.api-dev.deloitte.camp",
    onboarding: {
        displayVideo: true,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "GlobalDeloitteAi@deloitte.com"
    },
    support: {
        ccEmail: "",
        contact: "https://deloitteemea.service-now.com/mysupport?id=sc_cat_item&table=sc_cat_item&sys_id=36a2da9187688a50d1bb21bc8bbb35d7"
    },
    showGnosisBanner: true,
    gnosis_url: "https://solutions.deloitte.ai/catalog/global/prd_7a01844e-2234-46c3-9e3e-d6c62ed5f87a",
    onboarding_options: {
        "gen_ai": {
            api_url: "https://dcsp-geniusai.connect-dev.api.deloitte.ai",
            app_url: "https://geniusai-dev.solutions.deloitte.ai/workflow-selector"
        },
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
